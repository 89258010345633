import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-expedition-unknown-display',
    templateUrl: './expedition-unknown-display.component.html',
    styleUrls: ['./expedition-unknown-display.component.css'],
})
export class ExpeditionUnknownDisplayComponent {

  constructor() { }

}
