import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-finish-expedition-display',
    templateUrl: './finish-expedition-display.component.html',
    styleUrls: ['./finish-expedition-display.component.css'],
})
export class FinishExpeditionDisplayComponent {

  constructor() { }

}
