import { Component, OnInit } from '@angular/core';
import { NemesisDisplayComponent } from '../nemesis-display/nemesis-display.component';

@Component({
    selector: 'app-expedition-start-battle-display',
    imports: [NemesisDisplayComponent],
    templateUrl: './expedition-start-battle-display.component.html',
    styleUrls: ['./expedition-start-battle-display.component.css'],
})
export class ExpeditionStartBattleDisplayComponent {

  constructor() { }

}
